export const translationKeys = {
  welcomePageBalanseroTitle: 'welcomePage.balanseroTitle',
  welcomePageTitle: 'welcomePage.title',
  welcomePageSubtitle: 'welcomePage.subtitle',
  welcomePageGetStarted: 'welcomePage.getStarted',
  welcomePageHowItWorks: 'welcomePage.howItWorks',
  welcomePageFAQ: 'welcomePage.faq',
  welcomePageDiscover: 'welcomePage.discover',
  welcomePageInviteTeammatesTitle: 'welcomePage.inviteTeammatesTitle',
  welcomePageInviteTeammatesText: 'welcomePage.inviteTeammatesText',
  welcomePagePostGameTitle: 'welcomePage.postGameTitle',
  welcomePagePostGameText: 'welcomePage.postGameText',
  welcomePageAddPlayerTitle: 'welcomePage.addPlayerTitle',
  welcomePageAddPlayerText: 'welcomePage.addPlayerText',
  welcomePageGameHistoryTitle: 'welcomePage.gameHistoryTitle',
  welcomePageGameHistoryText: 'welcomePage.gameHistoryText',
  welcomePageEachPackIncludes: 'welcomePage.eachPackIncludes',
  welcomePageOneTimePayment: 'welcomePage.oneTimePayment',
  welcomePageMoneyBackGurantee: 'welcomePage.moneyBackGurantee',
  welcomePageIosAndAndroid: 'welcomePage.iosAndAndroid',
  welcomePageCustomizedLicence: 'welcomePage.customizedLicence',
  welcomePagePricingPlan: 'welcomePage.pricingPlan',
  welcomePageWhatIsBalanseroQuestion: 'welcomePage.whatIsBalanseroQuestion',
  welcomePageWhatIsBalanseroAnswer: 'welcomePage.whatIsBalanseroAnswer',
  welcomePageHowToCreateANewMatchQuestion: 'welcomePage.howToCreateANewMatchQuestion',
  welcomePageHowToCreateANewMatchAnswer: 'welcomePage.howToCreateANewMatchAnswer',
  welcomePageCanIInviteFriendsToJoinQuestion: 'welcomePage.canIInviteFriendsToJoinQuestion',
  welcomePageCanIInviteFriendsToJoinAnswer: 'welcomePage.canIInviteFriendsToJoinAnswer',
  welcomePageHowDoesTheTeamBalancingFeatureWorkQuestion:
    'welcomePage.howDoesTheTeamBalancingFeatureWorkQuestion',
  welcomePageHowDoesTheTeamBalancingFeatureWorkAnswer:
    'welcomePage.howDoesTheTeamBalancingFeatureWorkAnswer',
  welcomePageHowDoesTheVotingSystemWorkQuestion: 'welcomePage.howDoesTheVotingSystemWorkQuestion',
  welcomePageHowDoesTheVotingSystemWorkWorkAnswer:
    'welcomePage.howDoesTheVotingSystemWorkWorkAnswer',
  welcomePageCanIScheduleWeeklyMatches: 'welcomePage.canIScheduleWeeklyMatches',
  welcomePageCanIScheduleWeeklyMatchesAnswer: 'welcomePage.canIScheduleWeeklyMatchesAnswer',
  welcomePageCanIDeleteMyAccountQuestion: 'welcomePage.canIDeleteMyAccountQuestion',
  welcomePageCanIDeleteMyAccountAnswer: 'welcomePage.canIDeleteMyAccountAnswer',
  welcomePageIsBalanseroFreeQuestion: 'welcomePage.isBalanseroFreeQuestion',
  welcomePageIsBalanseroFreeAnswer: 'welcomePage.isBalanseroFreeAnswer',
  playerModalAddPlayer: 'playerModal.addPlayer',
  playerModalEditPlayer: 'playerModal.editPlayer',
  playerModalPlayersDeleted: 'playerModal.playersDeleted',
  playerModalDeleteTitle: 'playerModal.deleteTitle',
  playerModalSkillPlaceholder: 'playerModal.skillPlaceholder',
  organizationModalAddOrganization: 'organizationModal.addOrganization',
  organizationModalEditOrganization: 'organizationModal.editOrganization',
  organizationModalOrganizationsDeleted: 'organizationModal.organizationsDeleted',
  organizationModalDeleteTitle: 'organizationModal.deleteTitle',
  organizationModalDeleteInvite: 'organizationModal.deleteInvite',
  organizationModalLeaveOrganization: 'organizationModal.leaveOrganization',
  matchModalConfirmExit: 'matchModal.confirmExit',
  matchModalAddMatch: 'matchModal.addMatch',
  matchModalConfirmAddMatch: 'matchModal.confirmAddMatch',
  matchModalConfirmEditMatch: 'matchModal.confirmEditMatch',
  matchModalEditMatch: 'matchModal.editMatch',
  matchModalMatchesDeleted: 'matchModal.matchesDeleted',
  matchModalDeleteTitle: 'matchModal.deleteTitle',
  matchModalSelectPlayers: 'matchModal.selectPlayers',
  matchModalChooseRoster: 'matchModal.chooseRoster',
  matchModalDetails: 'matchModal.details',
  matchModalMatchName: 'matchModal.matchName',
  matchModalSendRate: 'matchModal.sendRate',
  matchModalTotalSkill: 'matchModal.totalSkill',
  matchModalCreatingMatch: 'matchModal.creatingMatch',
  matchModalSelectBetween: 'matchModal.selectBetween',
  matchModalConfirmBetween: 'matchModal.confirmBetween',
  matchModalEvenNumber: 'matchModal.evenNumber',
  matchModalBalanceQuestion: 'matchModal.balanceQuestion',
  matchModalBalanceInfo: 'matchModal.balanceInfo',
  matchModalBalanceInfoAdditional: 'matchModal.balanceInfoAdditional',
  matchModalClickHereToChangeRoster: 'matchModal.clickHereToChangeRoster',
  matchModalAutoBalanceInvite: 'matchModal.autoBalanceInvite',
  matchModalManualBalanceInvite: 'matchModal.manualBalanceInvite',
  matchModalManualBalanceOption: 'matchModal.manualBalanceOption',
  matchModalAutoBalanceOption: 'matchModal.autoBalanceOption',
  matchModalCreateMatch: 'general.createMatch',
  mathcModalPreviousPlayer: 'matchModal.previousPlayer',
  mathcModalNextPlayer: 'matchModal.nextPlayer',
  matchModalHowWouldYouRate: 'matchModal.howWouldYouRate',
  matchModalListPlayers: 'matchModal.listPlayers',
  matchModalListInvitedPlayers: 'matchModal.listInvitedPlayers',
  profileModalEditProfile: 'profileModal.editProfile',
  profileModalPasswordChange: 'profileModal.passwordChange',
  profileModalCurrentPasswordPlaceholder: 'profileModal.currentPasswordPlaceholder',
  profileModalNewPasswordPlaceholder: 'profileModal.newPasswordPlaceholder',
  profileModalConfirmPasswordPlaceholder: 'profileModal.confirmPasswordPlaceholder',
  joinOrganizationLogIn: 'joinOrganization.login',
  joinOrganizationSignUp: 'joinOrganization.signUp',
  joinOrganizationNotForYou: 'joinOrganization.notForYou',
  joinOrganizationJoin: 'joinOrganization.join',
  joinOrganizationAlreadyAnswered: 'joinOrganization.alreadyAnswered',
  joinOrganizationCreatePlayer: 'joinOrganization.createPlayer',
  joinOrganizationNewInvitation: 'joinOrganization.newInvitation',
  joinOrganizationManageOrganizations: 'joinOrganization.manageOrganizations',
  joinMatchJoin: 'joinMatch.join',
  joinMatchScheduledAt: 'joinMatch.scheduledAt',
  joinMatchAcceptInvitationQuestion: 'joinMatch.acceptInvitationQuestion',
  joinMatchInvitationAnswered: 'joinMatch.invitationAnswered',
  joinMatchChangeMind: 'joinMatch.changeMind',
  standard: 'general.standard',
  unlimited: 'general.unlimited',
  users: 'general.users',
  games: 'general.games',
  invites: 'general.invites',
  andMore: 'general.andMore',
  features: 'general.features',
  tryWithNoStringAttached: 'general.tryWithNoStringAttached',
  fullAccessForDays: 'general.fullAccessForDays',
  toContinueCreateAccountOrLogin: 'general.toContinueCreateAccountOrLogin',
  pricing: 'general.pricing',
  contactUs: 'general.contactUs',
  freeTrial: 'general.freeTrial',
  welcomeAboard: 'general.welcomeAboard',
  yourJourneyGotEvenBetter: 'general.yourJourneyGotEvenBetter',
  enjoyExclusivePerks: 'general.enjoyExclusivePerks',
  contact: 'general.contact',
  faq: 'general.faq',
  cancelSubscription: 'general.cancelSubscription',
  logIn: 'general.logIn',
  about: 'general.about',
  profile: 'general.profile',
  biling: 'general.biling',
  signOut: 'general.signOut',
  players: 'general.players',
  player: 'general.player',
  backTo: 'general.backTo',
  playersWhoDidNotRate: 'general.playersWhoDidNotRate',
  matchInvitationPreview: 'general.matchInvitationPreview',
  teamWearingBibs: 'general.teamWearingBibs',
  matches: 'general.matches',
  startYourFreeTrial: 'general.startYourFreeTrial',
  noPaymentInformationRequired: 'general.noPaymentInformationRequired',
  cancelWheneverYouWant: 'general.cancelWheneverYouWant',
  afterTrialExpiresLimited: 'general.afterTrialExpiresLimited',
  whatWeOffer: 'general.whatWeOffer',
  advanced: 'general.advanced',
  simple: 'general.simple',
  xMoreDays: 'general.xMoreDays',
  renewSubscription: 'general.renewSubscription',
  expires: 'general.expires',
  multipleWinsInARow: 'general.multipleWinsInARow',
  letsGetStarted: 'general.letsGetStarted',
  organizations: 'general.organizations',
  members: 'general.members',
  membersList: 'general.membersList',
  inviteMembers: 'general.inviteMembers',
  sendInvitations: 'general.sendInvitations',
  reinvite: 'general.reinvite',
  invite: 'general.invite',
  role: 'general.role',
  welcome: 'general.welcome',
  help: 'general.help',
  status: 'general.status',
  points: 'general.points',
  plan: 'general.plan',
  checkOutMyBalanseroStatistics: 'general.checkOutMyBalanseroStatistics',
  choosePayPeriodMethod: 'general.choosePayPeriodMethod',
  perMonth: 'general.perMonth',
  currentPlan: 'general.currentPlan',
  planStartingAfterTrialEnds: 'general.planStartingAfterTrialEnds',
  trialPlan: 'general.trialPlan',
  popularChoice: 'general.popularChoice',
  bestChoice: 'general.bestChoice',
  year: 'general.year',
  monthly: 'general.monthly',
  yearly: 'general.yearly',
  dateOfResponse: 'general.dateOfResponse',
  saveAnnually: 'general.saveAnnually',
  settings: 'general.settings',
  organization: 'general.organization',
  fullName: 'general.fullName',
  outcomeLastMatches: 'general.outcomeLastMatches',
  fullNamePlaceholder: 'general.fullNamePlaceholder',
  profilePhoto: 'general.profilePhoto',
  email: 'general.email',
  emailPlaceholder: 'general.emailPlaceholder',
  password: 'general.password',
  confirmPassword: 'general.confirmPassword',
  currentPassword: 'general.currentPassword',
  dayInAdvance: 'general.dayInAdvance',
  newPassword: 'general.newPassword',
  skill: 'general.skill',
  avgRating: 'general.avgRating',
  avgScore: 'general.avgScore',
  ratingForMatch: 'general.ratingForMatch',
  includesOneMonthTrial: 'general.includesOneMonthTrial',
  includesSixMonthsTrial: 'general.includesSixMonthsTrial',
  createdAt: 'general.createdAt',
  maxSkillGap: 'general.maxSkillGap',
  maxSkillGapInfo: 'general.maxSkillGapInfo',
  scheduledAt: 'general.scheduledAt',
  clearAllFilters: 'general.clearAllFilters',
  applyFilters: 'general.applyFilters',
  date: 'general.date',
  copied: 'general.copied',
  choose: 'general.choose',
  premiumOverlay: 'general.premiumOverlay',
  advancedStatisticsGraphs: 'general.advancedStatisticsGraphs',
  advancedPlayerStatistics: 'general.advancedPlayerStatistics',
  advancedMatchStatistics: 'general.advancedMatchStatistics',
  simplePlayerStatistics: 'general.simplePlayerStatistics',
  simpleMatchStatistics: 'general.simpleMatchStatistics',
  limitedMatchHistory: 'general.limitedMatchHistory',
  unlimitedMatchHistory: 'general.unlimitedMatchHistory',
  welcomeToBalanseroPremium: 'general.welcomeToBalanseroPremium',
  location: 'general.location',
  welcomeToBalansero: 'general.welcomeToBalansero',
  publicLinkInvitationMessage: 'general.publicLinkInvitationMessage',
  opponentTeamVoting: 'general.opponentTeamVoting',
  ratePlayers: 'general.ratePlayers',
  rated: 'general.rated',
  commentsEnabled: 'general.commentsEnabled',
  pressAndHoldToRate: 'general.pressAndHoldToRate',
  playDate: 'general.playDate',
  playDateTooltip: 'general.playDateTooltip',
  needToRatePlayersToolTip: 'general.needToRatePlayersToolTip',
  name: 'general.name',
  suggest: 'general.suggest',
  createdBy: 'general.createdBy',
  add: 'general.add',
  address: 'general.address',
  joinBalansero: 'general.joinBalansero',
  shareStatisticsLink: 'general.shareStatisticsLink',
  remove: 'general.remove',
  playerDetails: 'general.playerDetails',
  noCommentsYet: 'general.noCommentsYet',
  statistics: 'general.statistics',
  comments: 'general.comments',
  comment: 'general.comment',
  yourCommentForThisPlayer: 'general.yourCommentForThisPlayer',
  edit: 'general.edit',
  search: 'general.search',
  rowsPerPage: 'general.rowsPerPage',
  serbia: 'general.serbia',
  country: 'general.country',
  cancel: 'general.cancel',
  hiddenStat: 'general.hiddenStat',
  discoverBalansero: 'general.discoverBalansero',
  exploreBalanseroHassleFree: 'general.exploreBalanseroHassleFree',
  enjoyAllFeaturesWithoutRestriction: 'general.enjoyAllFeaturesWithoutRestriction',
  noPaymentRequired: 'general.noPaymentRequired',
  whatDoYouGet: 'general.whatDoYouGet',
  balancingPlayersBeforeMatches: 'general.balancingPlayersBeforeMatches',
  ratingSytemForPlayers: 'general.ratingSytemForPlayers',
  save: 'general.save',
  mostGoalsInAMatch: 'general.mostGoalsInAMatch',
  continue: 'general.continue',
  withoutRecurring: 'general.withoutRecurring',
  recurrenceInfo: 'general.recurrenceInfo',
  back: 'general.back',
  share: 'general.share',
  previous: 'general.previous',
  balance: 'general.balance',
  confirm: 'general.confirm',
  editRosterConfirmationTitle: 'general.editRosterConfirmationTitle',
  editRosterConfirmationSubtitle: 'general.editRosterConfirmationSubtitle',
  uploading: 'general.uploading',
  maxSize: 'general.maxSize',
  getStarted: 'general.getStarted',
  managePlayers: 'general.managePlayers',
  done: 'general.done',
  active: 'general.active',
  inactive: 'general.inactive',
  rejected: 'general.rejected',
  lineups: 'general.lineups',
  declined: 'general.declined',
  resultInputDetailsHelp: 'general.resultInputDetailsHelp',
  dropClick: 'general.dropClick',
  forgottenPassword: 'general.forgottenPassword',
  matchVenue: 'general.matchVenue',
  matchVenues: 'general.matchVenues',
  matchInvites: 'general.matchInvites',
  yourMatches: 'general.yourMatches',
  invitedToJoin: 'general.invitedToJoin',
  invitedPlayers: 'general.invitedPlayers',
  matchVenueSuggestions: 'general.matchVenueSuggestions',
  unspecifiedMatchVenue: 'general.unspecifiedMatchVenue',
  resetPassword: 'general.resetPassword',
  resetPasswordDescription: 'general.resetPasswordDescription',
  reset: 'general.reset',
  matchRatingType: 'general.matchRatingType',
  grade: 'general.grade',
  stars: 'general.stars',
  matchResultType: 'general.matchResultType',
  assists: 'general.assists',
  goals: 'general.goals',
  victories: 'general.victories',
  trialEndsInXDays: 'general.trialEndsInXDays',
  cancelWhenerver: 'general.cancelWhenerver',
  continueToUse: 'general.continueToUse',
  afterTrialCapabilities: 'general.afterTrialCapabilities',
  longitude: 'general.longitude',
  longitudePlaceholder: 'general.longitudePlaceholder',
  latitude: 'general.latitude',
  latitudePlaceholder: 'general.latitudePlaceholder',
  placeId: 'general.placeId',
  placeIdPlaceholder: 'general.placeIdPlaceholder',
  send: 'general.send',
  addResult: 'general.addResult',
  generateJoinLink: 'general.generateJoinLink',
  createAccount: 'general.createAccount',
  signInGoogle: 'general.signInGoogle',
  signUpGoogle: 'general.signUpGoogle',
  ifYouWantToSeeMoreMatches: 'general.ifYouWantToSeeMoreMatches',
  ifYouWantToSeeMoreStats: 'general.ifYouWantToSeeMoreStats',
  inviteBySharingLink: 'general.inviteBySharingLink',
  inviteByEmail: 'general.inviteByEmail',
  dontHaveAccount: 'general.dontHaveAccount',
  signUp: 'general.signUp',
  acceptedFormats: 'general.acceptedFormats',
  alreadyUser: 'general.alreadyUser',
  signInHere: 'general.signInHere',
  matchFormClickInfo: 'general.matchFormClickInfo',
  reject: 'general.reject',
  accept: 'general.accept',
  dayOfWeek: 'general.dayOfWeek',
  month: 'general.month',
  monday: 'general.monday',
  tuesday: 'general.tuesday',
  wednesday: 'general.wednesday',
  thursday: 'general.thursday',
  friday: 'general.friday',
  saturday: 'general.saturday',
  sunday: 'general.sunday',
  joinNow: 'general.joinNow',
  readMore: 'general.readMore',
  upgradeToPremium: 'general.upgradeToPremium',
  upgradeToPremiumToEnableViberNotifications: 'general.upgradeToPremiumToEnableViberNotifications',
  choosePlan: 'general.choosePlan',
  choosePricingPlan: 'general.choosePricingPlan',
  chooseTheRightPlan: 'general.chooseTheRightPlan',
  afterTrialEnds: 'general.afterTrialEnds',
  bestWorst: 'general.bestWorst',
  against: 'general.against',
  teammate: 'general.teammate',
  createMatch: 'general.createMatch',
  statisticType: 'general.statisticType',
  chooseStatisticsType: 'general.chooseStatisticsType',
  loading: 'general.loading',
  changeOrganizationSubtitle: 'general.changeOrganizationSubtitle',
  change: 'general.change',
  phoneNumber: 'general.phoneNumber',
  allTimeRating: 'general.allTimeRating',
  allTime: 'general.allTime',
  times: 'general.times',
  cantPlayAgainst: 'general.cantPlayAgainst',
  someoneWantsToPlayWithYou: 'general.someoneWantsToPlayWithYou',
  ratingLastMatches: 'general.ratingLastMatches',
  addInvitation: 'general.addInvitation',
  addNewInvitation: 'general.addNewInvitation',
  columnsToSort: 'general.columnsToSort',
  ascending: 'general.ascending',
  descending: 'general.descending',
  matchDetails: 'general.matchDetails',
  detailedStatistics: 'general.detailedStatistics',
  detailedStatisticsLastMatches: 'general.detailedStatisticsLastMatches',
  teamwork: 'general.teamwork',
  stamina: 'general.stamina',
  offense: 'general.offense',
  defense: 'general.defense',
  creativity: 'general.creativity',
  offenseShort: 'general.offenseShort',
  defenseShort: 'general.defenseShort',
  creativityShort: 'general.creativityShort',
  staminaShort: 'general.staminaShort',
  teamworkShort: 'general.teamworkShort',
  bestRatedPlayer: 'general.bestRatedPlayer',
  totalMatchesPlayed: 'general.totalMatchesPlayed',
  withinOrganization: 'general.withinOrganization',
  lastMatchResult: 'general.lastMatchResult',
  highestRating: 'general.highestRating',
  lowestRating: 'general.lowestRating',
  winningPercentage: 'general.winningPercentage',
  noMatchesPlayedInsideOrganization: 'general.noMatchesPlayedInsideOrganization',
  noWinsOrLossesInsideOrganization: 'general.noWinsOrLossesInsideOrganization',
  rate: 'general.rate',
  yes: 'general.yes',
  no: 'general.no',
  saveChanges: 'general.saveChanges',
  latest: 'general.latest',
  performanceInLastMatch: 'general.performanceInLastMatch',
  joined: 'general.joined',
  loginAndPassword: 'general.loginAndPassword',
  googleAccount: 'general.googleAccount',
  invitationExpired: 'general.invitationExpired',
  or: 'general.or',
  step: 'general.step',
  delete: 'general.delete',
  city: 'general.city',
  h2hComparisonAgainst: 'general.h2hComparisonAgainst',
  h2hComparisonTeammate: 'general.h2hComparisonTeammate',
  won: 'general.won',
  lost: 'general.lost',
  deleteSubtitle: 'general.deleteSubtitle',
  cancelSubtitle: 'general.cancelSubtitle',
  leaveSubtitle: 'general.leaveSubtitle',
  team: 'general.team',
  finish: 'general.finish',
  submitRatings: 'general.submitRatings',
  editRating: 'general.editRating',
  backToRating: 'general.backToRating',
  ratingsPreview: 'general.ratingsPreview',
  time: 'general.time',
  noDataToShow: 'general.noDataToShow',
  noResultsFound: 'general.noResultsFound',
  playersRatedNumber: 'general.playersRatedNumber',
  playersRated: 'general.playersRated',
  selected: 'general.selected',
  accepted: 'general.accepted',
  showMore: 'general.showMore',
  namePlaceholder: 'general.namePlaceholder',
  cityPlaceholder: 'general.cityPlaceholder',
  phoneNumberPlaceholder: 'general.phoneNumberPlaceholder',
  datePlaceholder: 'general.datePlaceholder',
  timePlaceholder: 'general.timePlaceholder',
  pageUnavailable: 'general.pageUnavailable',
  pageNotFound: 'general.pageNotFound',
  takeMeHome: 'general.takeMeHome',
  numberOfAppearances: 'general.numberOfAppearances',
  clickToEnterResult: 'general.clickToEnterResult',
  score: 'general.score',
  match: 'general.match',
  recurringMatch: 'general.recurringMatch',
  leave: 'general.leave',
  iosPwa: 'general.iosPwa',
  matchStatistic: 'general.matchStatistic',
  listOfMatchStatuses: 'general.listOfMatchStatuses',
  gotoHomePage: 'general.gotoHomePage',
  teamPreviewAfterMatchScheduled: 'general.teamPreviewAfterMatchScheduled',
  changeInviteResponse: 'general.changeInviteResponse',
  numberOfLastMatchesRatings: 'general.numberOfLastMatchesRatings',
  numberOfLastMatchesRatingsDesc: 'general.numberOfLastMatchesRatingsDesc',
  minNumberOfPlayersPerMatch: 'general.minNumberOfPlayersPerMatch',
  maxNumberOfPlayersPerMatch: 'general.maxNumberOfPlayersPerMatch',
  ratePlayersLeaveConfirmationTitle: 'general.ratePlayersLeaveConfirmationTitle',
  ratePlayersLeaveConfirmationSubtitle: 'general.ratePlayersLeaveConfirmationSubtitle',
  timeZone: 'general.timeZone',
  columnVisibility: 'general.columnVisibility',
  minMaxLabel: 'general.minMaxLabel',
  playerInfo: 'general.playerInfo',
  priorityGroup: 'general.priorityGroup',
  priorityGroupInfo: 'general.priorityGroupInfo',
  organizationInfo: 'general.organizationInfo',
  lastMatchesPlaceholder: 'general.lastMatchesPlaceholder',
  matchScorePlaceholder: 'general.matchScorePlaceholder',
  matchRateNotSent: 'general.matchRateNotSent',
  matchNotPartOf: 'general.matchNotPartOf',
  emailNotfications: 'general.emailNotfications',
  viberNotfications: 'general.viberNotfications',
  openViberText: 'general.openViberText',
  pushNotfications: 'general.pushNotfications',
  validationNameRequired: 'validation.nameRequired',
  validationCityRequired: 'validation.cityRequired',
  validationPlaceIdRequired: 'validation.placeIdRequired',
  validationLongitudeRequired: 'validation.longitudeRequired',
  validationLatitudeRequired: 'validation.latitudeRequired',
  validationLatitudeRange: 'validation.latitudeRange',
  validationLongitudeRange: 'validation.longitudeRange',
  validationNameRegex: 'validation.nameRegex',
  validationInvalidNameLength: 'validation.nameLength',
  validationEmailRequired: 'validation.emailRequired',
  validationInvalidEmail: 'validation.invalidEmail',
  validationSkillValidation: 'validation.skillValidation',
  validationPointsValidation: 'validation.pointsValidation',
  validationInvalidPassword: 'validation.invalidPassword',
  validationPasswordsMustMatch: 'validation.passwordsMustMatch',
  validationCurrentPasswordRequired: 'validation.currentPasswordRequired',
  validationNewPasswordRequired: 'validation.newPasswordRequired',
  validationPasswordRequired: 'validation.passwordRequired',
  validationConfirmPasswordRequired: 'validation.confirmPasswordRequired',
  validationSkillRequired: 'validation.skillRequired',
  validationDateRequired: 'validation.dateRequired',
  validationTimeRequired: 'validation.timeRequired',
  validationDateTimeRegex: 'validation.dateTimeRegex',
  validationFutureDateTime: 'validation.futureDateTime',
  validationPhoneNumberInvalid: 'validation.phoneNumberInvalid',
  validationScoreRequired: 'validation.validationScoreRequired',
  validationScoreValidation: 'validation.validationScoreValidation',
  validationLastMatchesRequired: 'validation.validationLastMatchesRequired',
  validationLastMatchesValidation: 'validation.validationLastMatchesValidation',
  validationSkillGapValidation: 'validation.validationSkillGapValidation',
  errorsUnknown: 'errors.unknown',
  errorsEmailInUse: 'errors.emailInUse',
  errorsWrongPassword: 'errors.wrongPassword',
  errorsEmailNotExisting: 'errors.emailNotExisting',
  errorsFillInForm: 'errors.fillInForm',
  errorsNotFound: 'errors.notFound',
  errorsBadRequest: 'errors.badRequest',
  errorsForbidden: 'errors.forbidden',
  errorsUnauthorized: 'errors.unauthorized',
  errorsPhoneInUse: 'errors.phoneInUse',
  errorsInvalidEmail: 'errors.invalidEmail',
  errorsUserOrganizationAlreadyExists: 'errors.userOrganizationAlreadyExists',
  errorsLastAdminRoleChangeError: 'errors.lastAdminRoleChangeError',
  errorsOrganizationLeave: 'errors.organizationLeave',
  confirmationYouHaveInvitation: 'confirmation.youHaveInvitation',
  confirmationDateScheduled: 'confirmation.dateScheduled',
  confirmationAnswerAccepted: 'confirmation.answerAccepted',
  confirmationYouHaveAlreadyAnswered: 'confirmation.youHaveAleadyAnswered',
  confirmationAccepting: 'confirmation.accepting',
  confirmationDeclining: 'confirmation.declining',
  confirmationChangeMyMind: 'confirmation.changeMyMind',
  confirmationModalSecureText: 'confirmationModal.secureText',
  languageLabel: 'language.label',
  englishLanguage: 'language.english',
  serbianLatinLanguage: 'language.serbianLatin',
  themeLabel: 'theme.label',
  themeDark: 'theme.dark',
  themeLight: 'theme.light',
  messagesLoginLoading: 'messages.loginLoading',
  messagesLoginSuccess: 'messages.loginSuccess',
  messagesError: 'messages.error',
  messagesCreatorLeavingError: 'messages.creatorLeavingError',
  messagesRegisterLoading: 'messages.registerLoading',
  messagesRegisterSucccess: 'messages.registerSucccess',
  messagesUpdateLoading: 'messages.updateLoading',
  messagesUpdateSucccess: 'messages.updateSuccess',
  messagesCreateLoading: 'messages.createLoading',
  messagesCreateSucccess: 'messages.createSuccess',
  messagesSuggestLoading: 'messages.suggestLoading',
  messagesSuggestSuccess: 'messages.suggestSuccess',
  messagesSaveLoading: 'messages.saveLoading',
  messagesSaveSuccess: 'messages.saveSuccess',
  messagesDeleteLoading: 'messages.deleteLoading',
  messagesDeleteSuccess: 'messages.deleteSuccess',
  messagesSendLoading: 'messages.sendLoading',
  messagesResetPasswordEmailSentSuccess: 'messages.resetPasswordEmailSentSuccess',
  messagesUsersReinviteSuccess: 'messages.usersReinviteSuccess',
  messagesUserReinviteSuccess: 'messages.userReinviteSuccess',
  messagesUsersInviteSuccess: 'messages.usersInviteSuccess',
  messagesRemindSuccess: 'messages.remindSuccess',
  messagesRebalanceLoading: 'messages.rebalanceLoading',
  messagesRebalanceSuccess: 'messages.rebalanceSuccess',
  messagesProcessLoading: 'messages.processLoading',
  messagesProcessSuccess: 'messages.processSuccess',
  messagesMyStatisticsError: 'messages.myStatisticsError',
  messagesResultUpdated: 'messages.resultUpdated',
  messagesNoMatchToRate: 'messages.noMatchToRate',
  messagesInvalidCredentials: 'messages.invalidCredentials',
  messagesViberInviteSuccess: 'messages.messagesViberInviteSuccess',
  messagesSubscriptionCancelled: 'messages.subscriptionCancelled',
  messagesSubscriptionRenewed: 'messages.subscriptionRenewed',
  messagesSubscriptionRenewing: 'messages.subscriptionRenewing',
  messagesSubscriptionCancelling: 'messages.subscriptionCancelling',
  matchStatusAll: 'matchStatus.all',
  matchStatusCreated: 'matchStatus.created',
  matchStatusInvitationSent: 'matchStatus.invitationSent',
  matchStatusScheduled: 'matchStatus.scheduled',
  matchStatusWaitingForResult: 'matchStatus.waitingForResult',
  matchStatusRateInvitationSent: 'matchStatus.rateInvitationSent',
  matchStatusDone: 'matchStatus.done',
  matchStatusCancelled: 'matchStatus.cancelled',
  matchGroupAll: 'matchGroup.all',
  matchGroupPlayedIn: 'matchGroup.playedIn',
  matchGroupNotPlayedIn: 'matchGroup.notPlayedIn',
  matchGroupWaitingForMyRate: 'matchGroup.waitingForMyRate',
  playerFilterAll: 'playerFilter.all',
  playerFilterAllGroups: 'playerFilter.allGroups',
  playerFilterTop10Rated: 'playerFilter.top10Rated',
  playerFilterTop10Frequent: 'playerFilter.top10Frequent',
  playerFilterWithViber: 'playerFilter.withViber',
  playerFilterWithoutViber: 'playerFilter.withoutViber',
  organizationFilterAll: 'organizationFilter.all',
  organizationFilterCreatedByMe: 'organizationFilter.createdByMe',
  organizationFilterUserRole: 'organizationFilter.userRole',
  organizationFilterAdminRole: 'organizationFilter.adminRole',
  guideMatchCreation: 'guide.matchCreation',
  guideMatchCreationText: 'guide.matchCreationText',
  guideViberSubscription: 'guide.viberSubscription',
  guideViberSubscriptionText: 'guide.viberSubscriptionText',
  guideTableOfContents: 'guide.tableOfContents',
  guideOrganizationInvite: 'guide.organizationInvite',
  guideOrganizationInviteText: 'guide.organizationInviteText',
  guidePriorityGroup: 'guide.priorityGroup',
  guidePriorityGroupText: 'guide.priorityGroupText',
  guideFindMore: 'guide.findMore',
  statusFilterAll: 'statusFilter.all',
  statusFilterAccepted: 'statusFilter.accepted',
  statusFilterDeclined: 'statusFilter.declined',
  statusFilterPending: 'statusFilter.pending',
  statusFilterNotSent: 'statusFilter.notSent',
  statusFilterExpired: 'statusFilter.expired',
  matchVenueModalAdd: 'matchVenueModal.add',
  matchVenueModalDeleteTitle: 'matchVenueModal.deleteTitle',
  matchVenueSuggestionModalAdd: 'matchVenueSuggestionModal.add',
  matchVenueSuggestionDeleteTitle: 'matchVenueSuggestionModal.deleteTitle',
  recurringMatchScheduleModalTitleEdit: 'recurringMatchModalSchedule.titleEdit',
  recurringMatchScheduleModalTitleAdd: 'recurringMatchModalSchedule.titleAdd',
  recurringMatchScheduleModalEdit: 'recurringMatchModalSchedule.edit',
  recurringMatchScheduleModalName: 'recurringMatchModalSchedule.name',
  recurringMatchScheduleModalCancelTitle: 'recurringMatchModalSchedule.cancelTitle',
  recurringMatchScheduleModalCancelSchedule: 'recurringMatchModalSchedule.cancelSchedule',
  recurringMatchScheduleModalWithExpiration: 'recurringMatchModalSchedule.withExpiration',
  recurringMatchScheduleModalAdd: 'recurringMatchModalSchedule.add',
  recurringMatchScheduleModalInitialAddInfo: 'recurringMatchModalSchedule.addInfo',
  accountDeletionRequestPageTitle: 'accountDeletionPage.title',
  accountDeletionRequestPageText: 'accountDeletionPage.text',
  accountDeletionRequestPagePrepareEmail: 'accountDeletionPage.prepareEmail',
  accountDeletionRequestPageSubjectLine: 'accountDeletionPage.subjectLine',
  accountDeletionRequestPageEmailBody: 'accountDeletionPage.emailBody',
  accountDeletionRequestPageSendEmail: 'accountDeletionPage.sendEmail',
  accountDeletionRequestPageContactSupportTeamMessage: 'accountDeletionPage.contactSupportTeam',
  accountDeletionRequestPageOpenEmailClient: 'accountDeletionPage.openEmailClient',
  accountDeletionRequestPageAddressEmailToSupport: 'accountDeletionPage.addressEmailToSupport',
  accountDeletionRequestPageUseSubjectLine: 'accountDeletionPage.useSubjectLine',
  accountDeletionRequestPageIncludeInformationsToEmail:
    'accountDeletionPage.includeInformationsToEmail',
  accountDeletionRequestPageIncludeFullName: 'accountDeletionPage.includeFullName',
  accountDeletionRequestPageIncludeUserName: 'accountDeletionPage.includeUserName',
  accountDeletionRequestPageIncludeEmail: 'accountDeletionPage.includeEmail',
  accountDeletionRequestPageIncludeReasonForDeleting:
    'accountDeletionPage.includeReasonForDeletion',
  accountDeletionRequestPageIncludeConfirmation: 'accountDeletionPage.includeConfirmation',
  accountDeletionRequestPageSendEmailMessage: 'accountDeletionPage.sendEmailMessage',
  accountDeletionRequestPageIfEncounterIssuesContactSupport:
    'accountDeletionPage.ifEncounterIssuesContactSupport',
  privacyPolicy: 'privacyPolicyPage.privacyPolicy',
  privacyPolicyPageTitle: 'privacyPolicyPage.title',
  privacyPolicyPageEffectiveDate: 'privacyPolicyPage.effectiveDate',
  privacyPolicyPageIntroductionTitle: 'privacyPolicyPage.introductionTitle',
  privacyPolicyPageIntroductionText: 'privacyPolicyPage.introductionText',
  privacyPolicyPageInformationWeCollectTitle: 'privacyPolicyPage.informationWeCollect.title',
  privacyPolicyPageInformationWeCollectText: 'privacyPolicyPage.informationWeCollect.text',
  privacyPolicyPagePersonalInformation:
    'privacyPolicyPage.informationWeCollect.personalInformation',
  privacyPolicyPageUsageData: 'privacyPolicyPage.informationWeCollect.usageData',
  privacyPolicyPageHowWeUseInformationTitle: 'privacyPolicyPage.howWeUseInformation.title',
  privacyPolicyPageHowWeUseInformationText: 'privacyPolicyPage.howWeUseInformation.text',
  privacyPolicyPageToProvideService: 'privacyPolicyPage.howWeUseInformation.toProvideService',
  privacyPolicyPageToManageAccounts: 'privacyPolicyPage.howWeUseInformation.toManageAccounts',
  privacyPolicyPageToCommunicateWithYou:
    'privacyPolicyPage.howWeUseInformation.toCommunicateWithYou',
  privacyPolicyPageToImproveServices: 'privacyPolicyPage.howWeUseInformation.toImproveServices',
  privacyPolicyPageSharingInformationTitle: 'privacyPolicyPage.sharingInformation.title',
  privacyPolicyPageSharingInformationText: 'privacyPolicyPage.sharingInformation.text',
  privacyPolicyPageWithYourConsent: 'privacyPolicyPage.sharingInformation.withYourConsent',
  privacyPolicyPageLegalObligations: 'privacyPolicyPage.sharingInformation.legalObligations',
  privacyPolicyPageSecurityOfInformationTitle: 'privacyPolicyPage.securityOfInformation.title',
  privacyPolicyPageSecurityOfInformationText: 'privacyPolicyPage.securityOfInformation.text',
  privacyPolicyPageDataProtectionRightsTitle: 'privacyPolicyPage.dataProtectionRights.title',
  privacyPolicyPageDataProtectionRightsText: 'privacyPolicyPage.dataProtectionRights.text',
  privacyPolicyPageAccess: 'privacyPolicyPage.dataProtectionRights.access',
  privacyPolicyPageRectification: 'privacyPolicyPage.dataProtectionRights.rectification',
  privacyPolicyPageDeletion: 'privacyPolicyPage.dataProtectionRights.deletion',
  privacyPolicyPageRestriction: 'privacyPolicyPage.dataProtectionRights.restriction',
  privacyPolicyPagePortability: 'privacyPolicyPage.dataProtectionRights.portability',
  privacyPolicyPageContact: 'privacyPolicyPage.dataProtectionRights.contact',
  privacyPolicyPageChangesToPrivacyPolicyTitle: 'privacyPolicyPage.changesToPrivacyPolicy.title',
  privacyPolicyPageChangesToPrivacyPolicyText: 'privacyPolicyPage.changesToPrivacyPolicy.text',
  privacyPolicyPageContactUsTitle: 'privacyPolicyPage.contactUs.title',
  privacyPolicyPageContactUsText: 'privacyPolicyPage.contactUs.text',
  privacyPolicyPageEmail: 'privacyPolicyPage.contactUs.email',
  privacyPolicyPageAddress: 'privacyPolicyPage.contactUs.address',
  privacyPolicyPageCompanyName: 'privacyPolicyPage.contactUs.companyName',
  june: 'general.june'
};
